<template>
	<div
		id="panel_advices"
		class="panel advices"
		:class="[
			isHeaderIndent? 'mt-0 pt-1' : '',
			isFooterIndent? 'mb-0 pb-1' : '',
		]"
	>
		<div class="container">
			<div
				v-if="isHeaderVisible"
				:class="[
					'panel_header',
					isBrand? 'sk' : '',
				]"
			>
				<div class="row align-items-end">
					<div class="col-12 col-lg-6">
						<h2 v-if="block.block_title === null" class="title">
							Рекомендуем
						</h2>
						<h2
							v-else
							class="title"
						>
							{{ block.block_title }}
						</h2>
					</div>
				</div>
			</div>
			<div class="advices_block">
				<ul class="advices_list row">
					<li
						v-for="(item, index) in block.items"
						:key="index"
						class="col-12 col-sm-6 col-md-6 col-lg-3"
					>
						<div class="advice-item">
							<div>
								<a
									v-if="!item.is_has_blocks && item.project_content_download_link"
									:href="item.project_content_download_link"
									target="_blank"
								>
									<b-img-lazy
										:src="item.listing_cover_small_blocks_url || ''"
										:alt="item.title"
										blank-color="lightgray"
										center
										block
									/>
								</a>
								<nuxt-link
									v-else
									:to="$url.media(item.slug, item.taxonomy_type_slug, {}, item.lang)"
								>
									<b-img-lazy
										:src="item.listing_cover_small_blocks_url || ''"
										:alt="item.title"
										blank-color="lightgray"
										center
										block
									/>
								</nuxt-link>
								<div
									class="date"
									:class="[
										'block_title_icon',
										'block_title_icon-' + item.type
									]"
								>
									{{ item.date | moment('DD MMMM YYYY') }}
								</div>
								<a
									v-if="!item.is_has_blocks && item.project_content_download_link"
									:href="item.project_content_download_link"
									class="title"
									target="_blank"
								>
									{{ item.title }}
								</a>
								<nuxt-link
									v-else
									:to="$url.media(item.slug, item.taxonomy_type_slug, {}, item.lang)"
									class="title"
								>
									{{ item.title }}
								</nuxt-link>
							</div>
							<div class="advice-item_bottom">
								<a
									v-if="!item.is_has_blocks && item.project_content_download_link"
									:href="item.project_content_download_link"
									class="more_link"
									target="_blank"
								>
									{{ $t('more') }}
								</a>
								<nuxt-link
									v-else
									:to="$url.media(item.slug, item.taxonomy_type_slug, {}, item.lang)"
									class="more_link"
								>
									{{ $t('more') }}
								</nuxt-link>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div v-if="isFooterVisible" class="panel_footer">
				<div class="row">
					<div class="col-12 col-sm-8">
						<dynamic-button
							:block-id="block.id"
							:button="block.button"
							:scenario="block.button.scenario"
						/>
					</div>
					<div class="col-12 col-sm-4">
						<div class="panel_footer_info" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import panel from '~/mixins/panel';
import '@/utils/moment';
import { mapActions } from 'vuex';
import { BImgLazy } from 'bootstrap-vue';

export default {
	name: 'PanelDynamicMedias',
	components: {
		BImgLazy,
	},
	mixins: [panel],
	data() {
		return {
			loginAl: true,
		};
	},
	created() {
		// this.checkValue();
	},
	methods: {
		...mapActions({
			showLoginPopup: 'authModal/showLogin',
		}),
		// ...mapMutations({
		// 	checkAlumni: 'alumni/panelDynamicMedias',
		// }),
		// checkValue() {
		// 	if (this.block.is_alumni === true) {
		// 		this.checkAlumni(true);
		// 	}
		// },
	},
};
</script>

<style lang="scss" scoped>
	@import "/assets/styles/components/blocks/issue-graduate-tips";
</style>

import { render, staticRenderFns } from "./PanelDynamicMedias.vue?vue&type=template&id=3a32ff1c&scoped=true"
import script from "./PanelDynamicMedias.vue?vue&type=script&lang=js"
export * from "./PanelDynamicMedias.vue?vue&type=script&lang=js"
import style0 from "./PanelDynamicMedias.vue?vue&type=style&index=0&id=3a32ff1c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a32ff1c",
  null
  
)


    import installComponents from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BImgLazy} from 'bootstrap-vue'
    installComponents(component, {BImgLazy})
    

export default component.exports
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel advices",class:[
		_vm.isHeaderIndent? 'mt-0 pt-1' : '',
		_vm.isFooterIndent? 'mb-0 pb-1' : '',
	],attrs:{"id":"panel_advices"}},[_c('div',{staticClass:"container"},[(_vm.isHeaderVisible)?_c('div',{class:[
				'panel_header',
				_vm.isBrand? 'sk' : '',
			]},[_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col-12 col-lg-6"},[(_vm.block.block_title === null)?_c('h2',{staticClass:"title"},[_vm._v("\n\t\t\t\t\t\tРекомендуем\n\t\t\t\t\t")]):_c('h2',{staticClass:"title"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.block.block_title)+"\n\t\t\t\t\t")])])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"advices_block"},[_c('ul',{staticClass:"advices_list row"},_vm._l((_vm.block.items),function(item,index){return _c('li',{key:index,staticClass:"col-12 col-sm-6 col-md-6 col-lg-3"},[_c('div',{staticClass:"advice-item"},[_c('div',[(!item.is_has_blocks && item.project_content_download_link)?_c('a',{attrs:{"href":item.project_content_download_link,"target":"_blank"}},[_c('b-img-lazy',{attrs:{"src":item.listing_cover_small_blocks_url || '',"alt":item.title,"blank-color":"lightgray","center":"","block":""}})],1):_c('nuxt-link',{attrs:{"to":_vm.$url.media(item.slug, item.taxonomy_type_slug, {}, item.lang)}},[_c('b-img-lazy',{attrs:{"src":item.listing_cover_small_blocks_url || '',"alt":item.title,"blank-color":"lightgray","center":"","block":""}})],1),_vm._v(" "),_c('div',{staticClass:"date",class:[
									'block_title_icon',
									'block_title_icon-' + item.type
								]},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm._f("moment")(item.date,'DD MMMM YYYY'))+"\n\t\t\t\t\t\t\t")]),_vm._v(" "),(!item.is_has_blocks && item.project_content_download_link)?_c('a',{staticClass:"title",attrs:{"href":item.project_content_download_link,"target":"_blank"}},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(item.title)+"\n\t\t\t\t\t\t\t")]):_c('nuxt-link',{staticClass:"title",attrs:{"to":_vm.$url.media(item.slug, item.taxonomy_type_slug, {}, item.lang)}},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(item.title)+"\n\t\t\t\t\t\t\t")])],1),_vm._v(" "),_c('div',{staticClass:"advice-item_bottom"},[(!item.is_has_blocks && item.project_content_download_link)?_c('a',{staticClass:"more_link",attrs:{"href":item.project_content_download_link,"target":"_blank"}},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.$t('more'))+"\n\t\t\t\t\t\t\t")]):_c('nuxt-link',{staticClass:"more_link",attrs:{"to":_vm.$url.media(item.slug, item.taxonomy_type_slug, {}, item.lang)}},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm.$t('more'))+"\n\t\t\t\t\t\t\t")])],1)])])}),0)]),_vm._v(" "),(_vm.isFooterVisible)?_c('div',{staticClass:"panel_footer"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-8"},[_c('dynamic-button',{attrs:{"block-id":_vm.block.id,"button":_vm.block.button,"scenario":_vm.block.button.scenario}})],1),_vm._v(" "),_vm._m(0)])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-sm-4"},[_c('div',{staticClass:"panel_footer_info"})])
}]

export { render, staticRenderFns }